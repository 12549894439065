/**
 * Created by xiongxiaomeng.
 * 客户相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    save: params => {  return API.POST('/sysuser/save', params)},
    list: params => {  return API.POST('/sysuser/list', params)},
    get: params => {  return API.POST('/sysuser/get', params)},
    delete: params => { return API.POST('/sysuser/delete', params) },
    saveUserResume: params => { return API.POST('/sysuser/saveUserResume', params) },
    saveUserExpection: params => { return API.POST('/sysuser/saveUserExpection', params) },
    deleteUserExpection: params => { return API.POST('/sysuser/deleteUserExpection', params) },
    saveUserExperience: params => { return API.POST('/sysuser/saveUserExperience', params) },
    deleteUserExperience: params => { return API.POST('/sysuser/deleteUserExperience', params) },
    saveUserEducation: params => { return API.POST('/sysuser/saveUserEducation', params) },
    deleteUserEducation: params => { return API.POST('/sysuser/deleteUserEducation', params) },
    saveUserCertification: params => { return API.POST('/sysuser/saveUserCertification', params) },
    deleteUserCertification: params => { return API.POST('/sysuser/deleteUserCertification', params) },
    uploadImg: params => { return API.POST('/uploadfile/uploadImg', params) },
    resetPassword: params => { return API.POST('/sysuser/resetPassword', params) },
    exportBlob: params => { return API.POST('/sysuser/export', params) },
    upgradeEmployer: params => { return API.POST("/sysuser/upgradeEmployer",params) },
}
